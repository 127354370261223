import { Avatar } from 'components/Avatar'
import { QrCode } from 'components/QrCode'
import { colors } from 'constants/colors'
import { format } from 'date-fns'
import { ApiService, TicketSchema } from 'openapi'
import { useEffect, useState } from 'react'
import { BsFillVolumeUpFill } from 'react-icons/bs'
import { GiStopSign } from 'react-icons/gi'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import useSound from 'use-sound'

const areSameDay = (date1: Date, date2: Date) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  )
}

type OpeningInfo = { openingTimes: string; saunaType: string }

const infosByDay: Record<number, OpeningInfo> = {
  0: { openingTimes: '10.00 Uhr - 23.00 Uhr', saunaType: 'Nur gemischt' },
  1: { openingTimes: '10.00 Uhr - 23.00 Uhr', saunaType: 'Nur Frauen ' },
  2: {
    openingTimes: '10.00 Uhr - 23.00 Uhr',
    saunaType: 'Gemischt mit Frauenbereich',
  },
  3: {
    openingTimes: '10.00 Uhr - 23.00 Uhr',
    saunaType: 'Gemischt mit Frauenbereich',
  },
  4: {
    openingTimes: '10.00 Uhr - 23.00 Uhr',
    saunaType: 'Gemischt mit Frauenbereich',
  },
  5: {
    openingTimes: '10.00 Uhr - 23.00 Uhr',
    saunaType: 'Gemischt mit Frauenbereich',
  },
  6: {
    openingTimes: '10.00 Uhr - 23.00 Uhr',
    saunaType: 'Gemischt mit Frauenbereich',
  },
}

const specialDays: Array<OpeningInfo & { date: Date }> = [
  {
    date: new Date('2024-12-24'),
    openingTimes: '10.00 Uhr - 17.00 Uhr',
    saunaType: 'Gemischt mit Frauenbereich',
  },
  {
    date: new Date('2024-12-25'),
    openingTimes: '10.00 Uhr - 17.00 Uhr',
    saunaType: 'Nur gemischt',
  },
  {
    date: new Date('2024-12-26'),
    openingTimes: '10.00 Uhr - 23.00 Uhr',
    saunaType: 'Nur gemischt',
  },
  {
    date: new Date('2024-12-31'),
    openingTimes: '10.00 Uhr - 17.00 Uhr',
    saunaType: 'Gemischt mit Frauenbereich',
  },
  {
    date: new Date('2025-01-01'),
    openingTimes: '14.00 Uhr - 23.00 Uhr',
    saunaType: 'Nur gemischt',
  },
  {
    date: new Date('2025-01-01'),
    openingTimes: '10.00 Uhr - 23.00 Uhr',
    saunaType: 'Nur gemischt',
  },
]

export const Monitor = () => {
  const [ping] = useSound('ping.mp3', { volume: 1 })

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.location.reload()
    }, 60 * 60 * 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  const today = new Date()
  const date = format(today, 'yyyy-MM-dd')

  const isSpecialDay = specialDays.find((v) => areSameDay(v.date, today))
  const infos = isSpecialDay || infosByDay[today.getDay()]

  const [mirrorEntry, setMirrorEntry] = useState<Array<TicketSchema>>()

  const { data: dayData, isLoading } = useQuery(
    `day:${date}`,
    () => ApiService.dayData(date),
    { refetchInterval: 5 * 1000 },
  )

  const wartende = dayData?.waitList.length ?? 0
  const wartendeOhneNull = wartende === 0 ? 1 : wartende

  useEffect(() => {
    if (
      dayData?.entryList.some((v) => !mirrorEntry?.some((m) => m._id === v._id))
    ) {
      ping()
      setMirrorEntry(dayData.entryList)
    }
  }, [dayData?.entryList, mirrorEntry, ping])

  if (!dayData || isLoading) {
    return <></>
  }

  if (!dayData.day.waitingListActive) {
    return (
      <Wrapper style={{ alignItems: 'center', flexDirection: 'column' }}>
        <BsFillVolumeUpFill
          size={40}
          color={'white'}
          style={{ zIndex: 1000, position: 'absolute', right: 8, bottom: 8 }}
          onClick={() => {
            ping()
          }}
        />
        <h1 style={{ fontSize: 80, margin: '64px 0px', color: colors.green }}>
          Keine Warteliste
        </h1>
        <h1 style={{ fontSize: 96, margin: '120px 0px' }}>
          HERZLICH WILLKOMMEN
        </h1>
        <div
          style={{
            background: 'white',
            padding: '32px 64px',
            margin: '64px 0px',
          }}
        >
          <h1>Heute: {infos.saunaType}</h1>
        </div>
        {/* <h1 style={{ margin: '48px 0px' }}>Öffnungszeiten</h1>
        <h1>{infosByDay[new Date().getDay()].openingTimes}</h1> */}
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Card style={{ flex: 3 }}>
        <div
          style={{ display: 'flex', alignItems: 'center', marginBottom: 48 }}
        >
          <GiStopSign size={140} color={colors.red} />
          <h1 style={{ fontSize: 72, marginLeft: 48, color: colors.red }}>
            Warteliste aktiv
          </h1>
        </div>
        <h1 style={{ margin: '40px 0px' }}>
          Bitte eintreten sobald Deine Nummer hier erscheint
        </h1>
        <EntryWrapper>
          {dayData.entryList.slice(-12).map((enter, idx) => (
            <Avatar key={idx} ticketNumber={enter.number} size={320} />
          ))}
        </EntryWrapper>
      </Card>
      <Card style={{ borderLeft: '4px solid grey', padding: 0 }}>
        <Card>
          <h1 style={{ marginBottom: 16 }}>In Warteliste eintragen</h1>
          <QrCode
            size={400}
            url={`https://warteliste.seebadenge.ch/${dayData.day._id}`}
          />
        </Card>
        <Card>
          <h1>
            Aktuell{' '}
            <span
              style={{ color: colors.red, fontSize: 72, margin: '0px 16px' }}
            >
              {wartendeOhneNull}
            </span>{' '}
            {`Wartende${wartendeOhneNull === 1 ? 'r' : ''}`}
          </h1>
        </Card>
        <Card>
          <h2>Nicht alleine?</h2>
          <h3 style={{ margin: '16px 0px' }}>
            Jede Person muss sich einzeln auf der Warteliste eintragen.
          </h3>
          <h2 style={{ margin: '16px 0px' }}>Kein Smartphone?</h2>
          <h3>Melde dich an der Kasse, um dich in die Liste einzutragen.</h3>
        </Card>
      </Card>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: row;
  padding: 64px 32px;
`

const Card = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
`

const EntryWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 16px;
  flex-wrap: wrap;
`
